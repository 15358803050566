@import "config";

.header {
  display: flex;
  height: $appHeaderHeight;
  min-height: $appHeaderHeight;
  padding: 0 $contentDefaultIndent * 2;
  background-image: linear-gradient(to bottom, darken($uiHighlightColor, 30%), darken($uiHighlightColor, 32%));
  color: $uiWhiteColor;
  &[data-superaccess] {
    background-image: linear-gradient(to bottom, darken($uiDarkColor, 22%), darken($uiDarkColor, 24%));
  }
  :global(.introjs) {
    &-showElement {
      color: $uiDarkColor;
    }
  }
  > nav {
    flex-wrap: nowrap;
    width: 100%;
    padding: 0;
    > div {
      flex-shrink: 0;
    }
    .userProfile {
      display: flex;
      align-items: center;
      margin-left: auto;
      font-size: 1.1em;
      > div:first-child {
        cursor: pointer;
        > div {
          > div:last-child {
            > div {
              font-size: 1.2em;
            }
          }
        }
      }
    }
    .langSelector {
      margin-left: 2em;
      padding: $contentDefaultIndent / 2 $contentDefaultIndent;
      border-color: rgba($uiWhiteColor, 0.25);
      background-color: rgba($uiBlackColor, 0.15);
      font-weight: normal;
      &:hover {
        background-color: rgba($uiWhiteColor, 0.95);
        color: $uiDarkColor;
      }
    }
    .langSelectorMenu {
      margin-top: $contentDefaultIndent;
    }
    .showIntroButton {
      margin-left: 2em;
      cursor: pointer;
      &:hover {
        svg {
          color: $uiWarningColor;
        }
      }
      svg {
        font-size: 2em;
      }
    }
  }
  .organizationName {
    flex: auto;
    margin-right: $contentDefaultIndent * 2;
    overflow: hidden;
    font-size: 1.125rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    img {
      max-width: 1.6em;
      + span {
        margin-left: 0.5em;
      }
    }
  }
  .businessSelectorWrapper {
    $iconSize: 1.8em;
    display: flex;
    align-items: center;
    min-width: 0;
    max-width: 20em;
    margin-right: 2em;
    > svg {
      flex: none;
      margin-left: 0.5em;
      font-size: 1.4em;
      cursor: pointer;
      &[data-syncing] {
        animation: rotate 2s linear infinite, blink 1.4s ease-out infinite;
        color: $uiHighlightColor;
        pointer-events: none;
      }
      &:hover {
        color: lighten($uiHighlightColor, 30%);
      }
    }
  }
  [disabled] {
    opacity: 1 !important;
  }
}
