@import "config";

.settingsDropDown {
  .dropDownToggle {
    &,
    &:active {
      padding: 0;
      border: 0;
      background: none !important;
      box-shadow: none !important;
    }
    > span {
      display: flex;
      align-items: center;
      .userName {
        display: inline-block;
        max-width: 30em;
        overflow: hidden;
        overflow: hidden;
        font-weight: 300;
        text-overflow: ellipsis;
        white-space: nowrap;
        &[data-superadmin] {
          svg {
            color: $uiWarningColor;
          }
        }
      }
      .organization {
        min-width: 20em;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        img {
          max-width: 1.4em;
          + span {
            margin-left: 0.5em;
          }
        }
        &[data-superaccess] {
          color: $uiWarningColor;
        }
      }
      .arrowIcon {
        margin-left: $contentDefaultIndent / 2;
      }
    }
  }
  .dropDownMenu {
    margin-top: $contentDefaultIndent;
    button {
      svg {
        font-size: 1.4em;
      }
      &:last-child {
        color: $uiNegativeColor;
      }
    }
  }
}
