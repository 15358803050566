@import "config";

.usersTable {
  .pendingBadge {
    display: inline-block;
    margin-right: 0.6em;
    border: 1px solid transparent;
    background-color: $uiSpecialColor;
    color: $uiWhiteColor;
    font-size: 0.8em;
    text-transform: uppercase;
  }
}
