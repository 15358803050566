@import "config";

body[data-desktop] {
  color: $uiDarkColor;
}

.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
[data-tooltip] {
  * {
    pointer-events: none;
  }
}
[data-desktop] {
  .app {
    height: 100%;
    overflow: hidden;
  }
}
