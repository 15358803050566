@import "config";

.addBusinessWindow {
  width: 420px;
  :global(.input) {
    &-group-prepend {
      > span {
        width: 90px;
      }
    }
  }
  .integrationServicesColumn {
    > div {
      width: 22em;
      margin: 1em 0;
      > button {
        position: relative;
        width: 100%;
        padding: 0.8em 1.2em;
        border-color: $uiBorderColor;
        color: $uiDarkColor;
        font-size: 1.1em !important;
        text-align: left;
        white-space: nowrap;
        + button {
          margin-top: 1em;
        }
        > img {
          width: 2em;
          margin-right: 0.5em;
        }
        .badge {
          position: absolute;
          top: -0.8em;
          right: -0.6em;
          background-color: $uiDarkColor;
          color: $uiWhiteColor;
          font-size: 0.8em;
          text-transform: capitalize;
          &.badgeDanger {
            background-color: $uiNegativeColor;
          }
        }
      }
    }
  }
  .vatPayerToggle {
    margin-right: auto;
  }
  &.integration {
    width: auto;
    .bodyClassName {
      padding: 0;
    }
  }
}
