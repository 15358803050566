.setupBackupWindow {
  width: 420px;
  .connectionInfo {
    width: 100%;
    > div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .buttonsContainer {
    margin-top: 1em;
    button {
      height: 2.9em;
      padding: 0.5em 1em;
    }
    .googleDrivePicker {
      > button {
        width: 100%;
      }
      + button {
        margin-left: 1em;
        white-space: nowrap;
        svg {
          font-size: 1.4em;
        }
      }
    }
  }
  &.accountConnected {
    .buttonsContainer {
      .googleDrivePicker {
        width: 100%;
      }
    }
  }
}
