@import "config";

$iconSize: 1.5rem;
.businessSelector {
  width: 14rem;
  padding: $contentDefaultIndent / 2;
  border-radius: 0.35rem;
  background-color: $uiWhiteColor;
  > a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
    color: darken($uiHighlightColor, 30%);
    font-weight: 500;
    > span {
      display: flex;
      align-items: center;
      overflow: hidden;
      img {
        width: $iconSize;
        height: $iconSize;
      }
      svg,
      img {
        margin-right: 0.3em;
        vertical-align: middle;
      }
      span {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .businessSelectorMenu {
    width: 18em;
    button {
      display: flex;
      align-items: center;
      overflow: hidden;
      > svg,
      img {
        width: $iconSize;
        height: $iconSize;
      }
      svg,
      img {
        margin-right: 0.3em;
        vertical-align: middle;
      }
      > svg {
        margin-right: 0;
      }
      span {
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &[disabled] {
        > span {
          color: darken($uiHighlightColor, 30%);
          font-weight: 500 !important;
        }
      }
    }
  }
  .businessSearch {
    height: 2.4em;
    margin-bottom: $contentDefaultIndent;
    padding: 0 0.75em;
  }
}

.businessSelectorToggle {
  display: block;
  margin: 0 $contentDefaultIndent;
  padding: $contentDefaultIndent * 2 0;
  border: none;
  border-bottom: 1px solid transparent;
  border-radius: 0;
  color: $uiDarkColor;
  font-size: 1em;
  line-height: 1;
  text-decoration: none;
  white-space: nowrap;
}
