.editUserSettingsWindow {
  width: 420px;
  :global(.input) {
    &-group {
      min-height: 2.9em;
    }
    &-group-prepend,
    &-group-append {
      > span,
      > button {
        width: 84px;
      }
    }
  }
  .messageGroup {
    justify-content: center;
    font-weight: 500;
  }
}
