@import "config";
@import "animations";

.editOrganizationSettingsWindow {
  $addonBlockWidth: 130px;
  $rowHeight: 2.9em;
  width: 820px;
  :global(.input) {
    &-group {
      min-height: $rowHeight;
    }
    &-group-prepend,
    &-group-append {
      > span,
      > button {
        width: $addonBlockWidth;
      }
    }
  }
  .activeOrganizationGroup {
    [data-superaccess] {
      border-color: lighten($uiWarningColor, 5%);
      background-image: linear-gradient(to bottom, lighten($uiWarningColor, 25%), lighten($uiWarningColor, 20%));
      color: $uiDarkColor;
    }
  }
  .tabSelector {
    margin-top: $contentDefaultIndent;
    .buttonBadge {
      display: inline-block;
      margin-left: 0.3em;
      border: 1px solid transparent;
      background-color: $uiDarkColor;
      color: $uiWhiteColor;
    }
  }
  .dataTableGroup {
    .tableColumn {
      align-items: center;
      height: 27em;
      .tableWrapper {
        width: 100%;
        max-height: 100%;
        overflow-y: auto;
        .dataTable {
          width: 100%;
        }
      }
    }
  }
  .otherGroup {
    padding-top: $contentDefaultIndent;
    button {
      height: $rowHeight;
      &.deleteOrganizationButton {
        margin-left: auto;
        border-width: 3px;
        text-transform: uppercase;
        + button {
          margin-left: $contentDefaultIndent;
        }
      }
      + button {
        margin-left: $contentDefaultIndent;
      }
    }
    .setupBackupButton {
      img {
        width: 1.2em;
        margin-right: 0.3em;
      }
    }
  }
  [data-wait] {
    animation: rotate 4s linear infinite;
  }
}
