.userDetailsWindow {
  width: 560px;
  :global(.input) {
    &-group {
      min-height: 2.9em;
    }
    &-group-prepend,
    &-group-append {
      > span,
      > button {
        width: 100px;
      }
    }
  }
  .allBusinessAllowedToggle {
    margin-right: auto;
  }
  .phoneNumberRow {
    min-height: 3em;
  }
}
