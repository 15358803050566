@import "config";

button,
[role=button] {
  &.btn {
    font-weight: normal !important;
    user-select: none;
    &-royal-blue {
      border-color: $uiSpecialColor;
      background-color: $uiSpecialColor;
      color: $uiWhiteColor;
      &:hover,
      &:focus {
        $hoverColor: darken($uiSpecialColor, 10%);
        border-color: $hoverColor;
        background-color: $hoverColor;
        color: $uiWhiteColor;
      }
      &:hover {
        box-shadow: 0 5px 15px rgba($uiBlackColor, 0.05), 0 4px 10px rgba($uiSpecialColor, 0.25);
      }
      &:focus {
        box-shadow: 0 0 0 3px rgba($uiSpecialColor, 0.15), 0 3px 15px rgba($uiSpecialColor, 0.2), 0 2px 5px rgba($uiBlackColor, 0.1);
      }
    }
    &-accent {
      border-color: $uiAccentColor;
      background-color: $uiAccentColor;
      color: $uiWhiteColor;
      &:hover,
      &:focus {
        $hoverColor: darken($uiAccentColor, 10%);
        border-color: $hoverColor;
        background-color: $hoverColor;
        color: $uiWhiteColor;
      }
      &:hover {
        box-shadow: 0 5px 15px rgba($uiBlackColor, 0.05), 0 4px 10px rgba($uiAccentColor, 0.25);
      }
      &:focus {
        box-shadow: 0 0 0 3px rgba($uiAccentColor, 0.15), 0 3px 15px rgba($uiAccentColor, 0.2), 0 2px 5px rgba($uiBlackColor, 0.1);
      }
    }
  }
}

// For gDrive picker
.picker {
  &-dialog {
    z-index: 2500 !important;
    &-bg {
      z-index: 2000 !important;
    }
  }
}

// Global extra styles
body {
  // Styles for desktop only
  &[data-desktop] {
    display: flex;
    height: 100%;
    overflow: hidden;
    color: $uiDarkColor;
    font-size: $uiDesktopFontSize;
    &:not([data-auth]),
    &:not([data-maintenance]) {
      min-width: $contentMinWidth;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    input,
    select {
      color: $uiDarkColor;
    }
    .app {
      [role=button],
      button {
        font-weight: normal !important;
        user-select: none;
      }
      input,
      textarea,
      select,
      button {
        font-size: $uiDesktopFontSize !important;
      }
    }
  }
  //For Shards component Tooltip
  &[data-calendar] {
    .tooltip {
      z-index: auto !important;
    }
  }
}
