.linkContactWindow {
  width: 420px;
  .selectContactInput {
    :global(.dropdown) {
      &-menu {
        min-width: calc(120%);
        margin: 0 -10%;
      }
    }
  }
}
