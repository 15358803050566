@import "config";

.commentsNotificationPopup {
  margin-left: 2em;
  .dropDownToggle {
    padding: 0;
    transition: transform 0.25s ease-out, opacity 0.25s ease-out;
    border: none !important;
    background: none !important;
    box-shadow: none !important;
    &:not(:global(.zero)) {
      transform: translate(-0.25em, 0.25em);
    }
    svg {
      font-size: 2em;
    }
    .notification {
      display: inline-block;
      position: absolute;
      top: -0.5em;
      left: 50%;
      transform: translateX(calc(-50% + 0.25em));
      transition: transform 0.25s ease-out;
      border: 1px solid transparent;
      background-color: $uiNegativeColor;
    }
  }
  .dropDownMenu {
    margin-top: $contentDefaultIndent;
    padding-bottom: $contentDefaultIndent * 2;
    .dropdownPrepend {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: $contentDefaultIndent;
      padding: $contentDefaultIndent;
      white-space: nowrap;
      .title {
        margin-right: $contentDefaultIndent * 2;
        font-size: 1.25rem;
      }
      + div {
        padding: 0;
      }
    }
    .dropDownItem {
      padding: $contentDefaultIndent;
      &[disabled] {
        opacity: 0.5 !important;
      }
      + button {
        border-top: 1px solid $uiBorderColor;
      }
      .content {
        margin: 0;
        overflow: hidden;
        line-height: 1.8;
        > div {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        svg {
          flex: none;
          font-size: 1.2em;
        }
        .subTitle {
          display: flex;
          align-items: center;
          > span {
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .info {
            margin-right: $contentDefaultIndent;
            font-size: 1.2em;
          }
        }
        .userInfo {
          > span {
            opacity: 0.5;
          }
        }
      }
      .notification {
        display: inline-block;
        flex: none;
        margin-left: auto;
        border: 1px solid transparent;
        background-color: $uiNegativeColor;
        color: $uiWhiteColor;
        svg {
          font-size: 1.1em;
        }
      }
    }
  }
}
