@import "config";
@import "animations";

.navBar {
  display: flex;
  position: fixed;
  z-index: 1000;
  top: 0;
  bottom: 0;
  left: 0;
  flex-direction: column;
  align-items: flex-start;
  width: 4.25rem;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  transition: width 0.2s; // stylelint-disable-line plugin/no-low-performance-animation-properties
  border: none;
  border-bottom: 1px solid $uiNeutralColor;
  background-color: $uiWhiteColor;
  box-shadow: 0 2px 6px rgba($uiDarkColor, 0.15);
  backdrop-filter: blur(6px);
  .logo {
    display: flex;
    align-items: center;
    width: 100%;
    height: 3.75rem;
    min-height: 3.75rem;
    .image {
      display: flex;
      justify-content: center;
      width: 100%;
      img {
        max-width: 3.25rem;
        height: 100%;
        max-height: 2rem;
      }
    }
  }
  .navTabs {
    display: flex;
    flex: auto;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    border-bottom: none;
    font-size: 1em;
    white-space: nowrap;
    .navLink {
      position: relative;
      flex-grow: 1;
      min-height: 2.5rem;
      max-height: 3.5rem;
      padding: 0 $contentDefaultIndent;
      color: $uiDarkColor;
      text-decoration: none;
      &:hover,
      &:global(.active) {
        color: $uiHighlightColor;
      }
      &:global(.active)::after {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        width: 3px;
        height: 2.25rem;
        transform: translate(0, -50%);
        border-radius: 3px 0 0 3px;
        background-color: $uiHighlightColor;
      }
      .inner {
        display: flex;
        position: relative;
        align-items: center;
        width: 100%;
        height: 100%;
        margin: $contentDefaultIndent / 2 0;
        padding: 0 $contentDefaultIndent * 2;
        overflow: hidden;
        font-size: 1em;
        .notification {
          position: absolute;
          top: 0;
          right: 0;
          min-width: 1.3rem;
          margin-left: auto;
          padding: 0.15rem 0.2rem;
          transform: translate3d(-4px, 10px, 0);
          transition: transform 0.2s;
          background-color: $uiNegativeColor;
          color: $uiWhiteColor;
          font-size: 0.6875rem;
          line-height: 0.75rem;
          &[data-theme=warning] {
            background-color: $uiWarningColor;
          }
          &.upload {
            animation: blink 1s ease-out infinite;
          }
        }
        .iconWrap {
          display: flex;
          position: relative;
          svg {
            flex: none;
            font-size: 1.25rem;
          }
        }
        .title {
          margin-right: $contentDefaultIndent;
          margin-left: $contentDefaultIndent;
          overflow: hidden;
          transition: opacity 0.2s;
          opacity: 0;
          text-overflow: ellipsis;
        }
      }
      .betaBadge {
        display: flex;
        position: absolute;
        top: 50%;
        left: 0;
        align-items: center;
        justify-content: center;
        width: 2.25rem;
        transform: translate(-9px, -50%) rotate(90deg);
        border-radius: 5px 5px 0 0;
        background-color: $uiSpecialColor;
        color: $uiWhiteColor;
        font-size: 0.5625rem;
        text-transform: uppercase;
      }
    }
  }
  &:hover {
    width: $menuFullWidth;
    .navTabs {
      .navLink {
        .inner {
          .title {
            opacity: 1;
          }
          .notification {
            flex-shrink: 0;
            transform: translate3d(-4px, 19px, 0);
          }
        }
      }
    }
  }
}
@media (min-width: $menuFullWidthMinViewport) {
  .navBar {
    width: $menuFullWidth;
    .navTabs {
      .navLink {
        .inner {
          .title {
            overflow: hidden;
            opacity: 1;
            text-overflow: ellipsis;
          }
          .notification {
            flex-shrink: 0;
            transform: translate3d(-4px, 19px, 0);
          }
        }
      }
    }
  }
}
