@import "config";

.noBusiness {
  display: flex;
  flex: auto;
  width: 100%;
  margin: auto;
  padding: $contentDefaultIndent * 2;
  overflow: auto;
  background-color: $uiWhiteColor;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 22rem;
    margin: auto !important;
    padding: 2em;
    border-radius: $contentDefaultIndent;
    background-color: $uiWhiteColor;
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.15);
    .icon {
      opacity: 0.3;
      color: $uiWarningColor;
      font-size: 8rem;
    }
    .title {
      font-size: 1.5rem;
      word-break: break-word;
    }
    .description {
      margin-top: 0.5rem;
      padding: 0 0.5rem;
      color: $uiMediumDarkColor;
      font-size: 0.9rem;
    }
  }
  .addNewBusiness {
    margin: auto;
    text-align: center;
    h3 {
      color: $uiMediumDarkColor;
    }
    h5 {
      color: $uiNeutralColor;
    }
    button {
      margin-top: 1em;
      padding: 0.75em 1em;
    }
  }
  .card {
    padding-top: $contentDefaultIndent * 2;
    border-radius: $contentDefaultIndent;
    background-color: $uiWhiteColor;
    box-shadow: 1px 2px 16px rgba($uiBlackColor, 0.05), -1px 2px 16px rgba($uiBlackColor, 0.05);
    h5 {
      margin: 0;
      padding-bottom: 1rem;
      border-bottom: 1px solid $uiBorderColor;
    }
  }
}
body[data-mobile] {
  .noBusiness {
    .container {
      box-shadow: none;
    }
  }
}
